<template>
  <div class="xpp-toolbar-box" :class="editToolbar">
    <div class="xpp-toolbar">
      <div
        class="drawing-color"
        v-for="(item, index) in colorlist"
        :key="index"
        :class="{ active: item === noteDefalutInfo.backgroundColor }"
        @click="noteBgCheck(item)"
      >
        <div class="color-icon" :style="{ backgroundColor: item }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "NoteBgToolbar",
  computed: {
    editToolbar() {
      return {
        stretch: this.slideSidebarOpen,
        active: this.editToolbarActive === 4
      };
    },
    ...mapGetters(["noteDefalutInfo", "editToolbarActive", "slideSidebarOpen"])
  },
  data() {
    return {
      colorlist: [
        "#fff393",
        "#ffd342",
        "#ff7d21",
        "#ff59b6",
        "#f0ff7e",
        "#d9ef34",
        "#36edc4",
        "#009077",
        "#ccf6ff",
        "#a9e4ff",
        "#51b1ff",
        "#2971ff",
        "#e9f1ff",
        "#d9e1ef",
        "#4E4E4E",
        "#333333",
        "transparent"
      ]
    };
  },
  methods: {
    noteBgCheck(val) {
      this.$SetNoteInfo({
        backgroundColor: val
      });
    },
    ...mapMutations(["$SetNoteInfo"])
  }
};
</script>

<style lang="scss" scoped>
.drawing-color {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover,
  &.active {
    .color-icon {
      transform: scale(1.1);
      box-shadow: 0 0 0 2px #fff;
    }
  }
  &:last-child {
    .color-icon {
      background-image: url(/img/color_transpa.png);
    }
  }
  .color-icon {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff;
    transition: all 0.2s;
  }
}
</style>
